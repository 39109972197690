import React, { Component } from 'react';
import { Card, Container, Row } from 'react-bootstrap';

//styling
import "../styles/cardHeader.css"

export class CardHeader extends Component {
    render() {
        return (
            <Container className="card-container" fluid>
            <Card className="card-header" fluid>
                <Row className="justify-content-center" style={{textAlign:'center'}}>
                    <h1 className="nama-baristand">BARISTAND INDUSTRI BANDA ACEH</h1>
                    <h8 className="judul-survey">SURVEY KEPUASAN PELANGGAN PADA PELAYANAN BARISTAND BANDA ACEH</h8>
                </Row>
            </Card>
            </Container>
        )
    }
}

export default CardHeader;
